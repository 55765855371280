import React, {useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import AboutNavigation from "../../components/about/aboutNavigation";
import Button from "../../components/global/button";
import HeroImage from "../../components/global/heroImage";
import Image from "../../components/global/image"
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import Select from "../../components/global/select";
import LogoLarge from  "../../images/chicken-soup-for-the-soul-company-logo-large.jpg"
import LogoSmall from  "../../images/chicken-soup-for-the-soul-company-logo-small.png"
import LogoSoupman from  "../../images/chicken-soup-for-the-soul-soupman-spine-logo.jpg"
import defaultFrontCover from "../../images/coming-soon-front-cover.png"
import "./book-art-product-photos-logos.scss"

const BookArtProductPhotosPage = () => {

    const data = useStaticQuery(graphql`
    {
      craft {
        books: entries(section: "bookPage", originalPublisher: "chickenSoupForTheSoul", orderBy: "publishedDate desc") {
          ... on Craft_bookPage_default_Entry {
            id
            slug
            title
            value: id
            bookFrontCover {
                key:id,
                smallUrl: url(transform:"bookCoverMedium")
                largeUrl: url
                ... on Craft_websiteBookPage_Asset {
                    altText
                }
            }
          }
        }
        entry(section: "coverArtLogos") {
          ... on Craft_coverArtLogos_coverArtLogos_Entry {
            id
            uri
            title
            aboutUsHeroImage {
              ... on Craft_aboutUsHeroImage_image_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websiteGeneral_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
          }
        }
      }
    }
  `)

    const changeBookCover = (event) => {
        let book = data.craft.books.find(elem => elem.id === event.target.value)

        if (book) {
            setActiveBook(book)
        } else {
            setActiveBook(false)
        }
    }

    const addBookCoverPlaceholder = () => {
        if (data.craft.books[0].id === null) {
            return null
        } else {
            data.craft.books.unshift({
                title: "Please Select a Cover",
                slug: "",
                value: "",
                id: null
            })
        }
    }
    addBookCoverPlaceholder()

    const getBookFrontCoverSmall = () => {
        if (activeBook.id) {
            if (activeBook.bookFrontCover.length > 0) {
                return activeBook.bookFrontCover[0].smallUrl
            } else {
                return defaultFrontCover
            }
        }
    }

    const getBookFrontCoverLarge = () => {
        if (activeBook.id) {
            if (activeBook.bookFrontCover.length > 0) {
                return activeBook.bookFrontCover[0].largeUrl
            } else {
                return defaultFrontCover
            }
        }
    }

    const getBookFrontCoverAltText = () => {
        if (activeBook.id) {
            if (activeBook.bookFrontCover.length > 0) {
                return activeBook.bookFrontCover[0].altText
            } else {
                return null
            }
        }
    }

    const getBookPost = () => {
        if (activeBook.id) {
            return (
                <div className="choose-book-post">
                    <Image src={getBookFrontCoverSmall()} alt={getBookFrontCoverAltText()}/>
                    <Button to={getBookFrontCoverLarge()} classes="btn-primary" text="Download Cover" openInNewTab={true}></Button>
                    <Button to={`/book/${activeBook.slug}`} classes="btn-primary" text="See More Details" openInNewTab={true}></Button>
                </div>
            )
        } else {
            return null
        }
    }

    const [activeBook, setActiveBook] = useState(false)

    return (
        <InnerLayout seomatic={data.craft.entry.seomatic} entryId={data.craft.entry.id}>
            <div className="midd book-art-product-photos-logos-page">

                <PageTitle title={data.craft.entry.title} />

                <div className="content-page">
                    <div className="container-small">

                        <HeroImage image={data.craft.entry.aboutUsHeroImage}/>

                        <div className="content-colum-row">

                            <div className="sidebar">
                                <AboutNavigation active={data.craft.entry.uri} />
                            </div>

                            <div className="content-right">

                                <h3>Book Front Cover Art & Company Logos</h3>

                                <h4>COMPANY LOGOS</h4>
                                <p>To download a company logo, right-click on the link and choose to “Save Link As...” or left-click to view the image and then save.</p>

                                <div className="company-logo">
                                    <ul>
                                        <li>
                                            <a href={LogoLarge} aria-label="Download the company logo" target="_blank" rel="noreferrer">
                                                <img src={LogoSmall} alt="Chicken Soup for the Soul company logo" width="251" />
                                                <span>Download</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={LogoSoupman} aria-label="Download the book spine logo" target="_blank" rel="noreferrer">
                                                <img src={LogoSoupman} alt="Chicken Soup for the Soul Soupman spine logo" width="86" />
                                                <span>Download</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <h4>CHOOSE A BOOK</h4>
                                <p>To download a high-resolution image of a book cover, select a title from the dropdown menu below. A download link for the requested artwork will appear; right-click on the link and choose to save the image, or left-click to view the image in your browser and then save. Please allow some time for the image to load, as some of them can be almost 2MB in size.</p>


                                <div className="choose-book">
                                    <h4>Choose Book (newest titles appear first):</h4>

                                    <div className="frm-row">
                                        <Select options={data.craft.books} changeHandler={changeBookCover}></Select>
                                    </div>

                                </div>

                                {getBookPost()}
                                
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </InnerLayout>
    )
}

export default BookArtProductPhotosPage
